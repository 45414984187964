<script>
import { mapActions } from 'vuex'

export default {
  name: 'TracksList',
  components: {
    Action: () => import('@/components/general/Action'),
    Dropdown: () => import('@/components/general/Dropdown'),
    Datatable: () => import('@/components/general/Datatable'),
    FilterList: () => import('@/components/general/FilterList'),
    Pagination: () => import('@/components/general/Pagination'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    FilterListSearch: () => import('@/components/general/FilterListSearch'),
    SelectField: () => import('@/components/general/SelectField')
  },

  data () {
    return {
      pagination: {
        page: 1,
        lastPage: null,
        filter: {
          only_name: true
        }
      },

      search: null,
      modalToggleActiveOpen: false,
      trackId: null,
      genreFilter: null,
      genreFilterList: [
        {
          text: this.$t('tracks.list:filter.1'),
          value: 'curso_ead'
        },
        {
          text: this.$t('tracks.list:filter.2'),
          value: 'dicas_whatsapp'
        },
        {
          text: this.$t('tracks.list:filter.3'),
          value: 'presencial'
        }
      ]
    }
  },

  computed: {
    tracksList () {
      return this.$store.getters.tracksList
    },

    canWrite () {
      return this.getContextPermission('tracks') === 'write'
    }
  },

  watch: {
    'pagination.page' () {
      this.getTrackList()
    }
  },

  created () {
    this.getTrackList()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetTracksList',
      'attemptGetTracksSearch',
      'attemptManageTrack',
      'attemptToggleActiveTrack'
    ]),

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
      }
    },

    nextPage () {
      this.pagination.page += 1
    },

    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.pagination.lastPage
    },

    getTrackList () {
      this.setFetching(true)

      this.pagination.order = {
        active: 'desc',
        name: 'asc'
      }

      if (this.search !== '' && this.search !== null) {
        this.attemptGetTracksSearch(this.pagination).then(pagination => {
          this.pagination.lastPage = pagination.lastPage
        }).finally(() => {
          this.setFetching(false)
        })

        return
      }

      this.attemptGetTracksList(this.pagination).then(pagination => {
        this.pagination.lastPage = pagination.lastPage
      }).finally(() => {
        this.setFetching(false)
      })
    },

    getTrackLength (trackId) {
      const track = this.tracksList.find(track => track.id === trackId)

      return track.learningPathSolutions.length
    },

    createNewTrack () {
      this.attemptManageTrack(null)
      this.$router.push({ name: 'tracks.manage' })
    },

    searchTrack (search) {
      this.search = search

      this.pagination.query = {
        name: search
      }

      this.firstPage()
      this.getTrackList()
    },

    edit (trackId, tab = 1) {
      this.$router.push({
        name: 'tracks.manage',
        params: {
          id: trackId,
          tab: tab
        }
      })
    },

    openPage (track) {
      window.open(`${process.env.VUE_APP_PORTAL_URL}trilha-de-aprendizagem/${track.slug}`, '_blank')
    },

    toggleActive (trackId, toggleFlag) {
      this.setFetching(true)

      this.attemptToggleActiveTrack(trackId).then(() => {
        this.getTrackList()
        const toggleValue = toggleFlag ? 'deactivate' : 'activate'

        this.setFeedback({ message: this.$t(`tracks:feedback.toggle.${toggleValue}.success`) })
      }).catch((e) => {
        if (e.data.error.message === 'learning_path_solution_not_found') {
          this.setFeedback({ message: this.$t('global:form.tracks.toggle.learning_path.solution.error') })
        } else {
          this.setFeedback({ message: this.$t('global.error') })
        }
      }).finally(() => {
        this.setFetching(false)
      })
    }
  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('tracks:header.title')"
      :description="$t('tracks:header.description')"
      background="/assets/images/themes/default/png/tracks_banner.png"
    />
    <FilterList>
      <Action
        v-if="canWrite"
        slot="button"
        primary
        large
        fixed-width
        type="button"
        :text="$t('tracks.list:btn.add')"
        @click="createNewTrack"
      />
      <SelectField
        v-model="genreFilter"
        :label="$t('tracks.list:filter.1')"
        :items="genreFilterList"
      />
      <FilterListSearch
        slot="search"
        :hide-error-details="true"
        @search="searchTrack"
      />
    </FilterList>
    <div
      v-if="tracksList.length > 0"
      class="center tracks-table"
    >
      <Datatable :items="tracksList">
        <template slot="thead">
          <tr>
            <th class="th">
              {{ $t('tracks.list:datatable.header.1') }}
            </th>
            <th class="th">
              {{ $t('tracks.list:datatable.header.2') }}
            </th>
            <th class="th">
              {{ $t('tracks.list:datatable.header.3') }}
            </th>
            <th
              class="th"
              width="40"
            />
          </tr>
        </template>
        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr>
            <td class="td">
              <span class="td-text bolder">{{ props.item.name }}</span>
            </td>
            <td class="td">
              <span class="td-text">{{ getTrackLength(props.item.id) }}</span>
            </td>
            <td class="td">
              <span
                v-if="props.item.active === 1"
                class="td-tag active-ribbon"
              >{{ $t('global.status:active') }}</span>
              <span
                v-else
                class="td-tag inactive-ribbon"
              >{{ $t('global.status:inactive') }}</span>
            </td>
            <td
              class="td td-actions"
              width="40"
            >
              <Dropdown
                icon="dots-vertical"
                right
              >
                <DropdownLink
                  v-if="canWrite"
                  :text="$t('global:edit')"
                  @click="edit(props.item.id)"
                />
                <DropdownLink
                  :text="$t('global:show.page')"
                  @click="openPage(props.item)"
                />
                <DropdownLink
                  :text="$t('tracks.list:action.links')"
                  @click="edit(props.item.id, 4)"
                />
                <DropdownLink
                  v-if="!props.item.active && canWrite"
                  :text="$t('global:activate')"
                  @click="toggleActive(props.item.id, props.item.active)"
                />
                <DropdownLink
                  v-else-if="canWrite"
                  :text="$t('global:deactivate')"
                  @click="toggleActive(props.item.id, props.item.active)"
                />
              </Dropdown>
            </td>
          </tr>
        </template>
      </Datatable>
      <Pagination
        :active-page="pagination.page"
        :page-count="pagination.lastPage"
        @firstPage="firstPage"
        @lastPage="lastPage"
        @nextPage="nextPage"
        @previousPage="prevPage"
      />
    </div>
    <div
      v-else
      class="center tracks-table"
    >
      <p>Nenhuma trilha encontrada para o termo pesquisado</p>
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/tracks.css"></style>
